$(document).ready(function () {

    $('.cim_mapbox').each(function() {
        let $themap = $(this).find('.the_map');
        let map_id = $themap.attr('id');
        
        let latitude = parseFloat($themap.attr('data-latitude'));
        let longitude = parseFloat($themap.attr('data-longitude'));

        let sourceType = $themap.attr('data-sourtype');
        let rootPath = $themap.attr('data-rootpath');
        let configFile = $themap.attr('data-config-file') || 'master/default';
        let pid = parseInt($themap.attr('data-pid'));
        let uid = parseInt($themap.attr('data-uid'));

        let config = {
            el: map_id,
            source: "osm",
            configFile: configFile,
            rootPath: rootPath,
        };
        let map = new CimeosMapbox(config);

        if (latitude !== undefined && longitude !== undefined) {
            map.addMarker({
                latitude: latitude,
                longitude: longitude,
                properties: {
                    sourceType: sourceType,
                    pid: pid,
                    uid: uid,
                }
            });
        }

        $(map).on('load', function() {
            map.fitBounds();
        });
    });

});


